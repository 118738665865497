import React, { createContext } from 'react';

const DialogCityContext = createContext({});
export function DialogCityProvider({ children, title }) {
  return (
    <DialogCityContext.Provider value={{ title }}>
      {children}
    </DialogCityContext.Provider>
  );
}

export default DialogCityContext;
