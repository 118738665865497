import React, { useState, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import Image from 'material-ui-image';

import { Dialog } from '../../../../components';

import InitialPage from './components/InitialPage';
import DataTable from './components/DataTable';

import { STATUS } from './assets/constants';
import { DialogCityProvider } from './assets/context';

function DialogCity(props) {
  const { cityData, show, onClose } = props;

  const [status, setStatus] = useState('initial');

  const closeDialog = useCallback(() => {
    onClose();
    setStatus('initial');
  }, [onClose]);

  const title = useMemo(() => {
    switch (status) {
      case STATUS.PEOPLE_EFL:
        return `${cityData.name} - Pessoas EFL`;
      case STATUS.PEOPLE_SVN:
        return `${cityData.name} - Pessoas SVN`;
      case STATUS.PEOPLE_GREEN:
        return `${cityData.name} - Pessoas Verdes`;
      case STATUS.PEOPLE_YELLOW:
        return `${cityData.name} - Pessoas Amarelas`;
      case STATUS.PEOPLE_RED:
        return `${cityData.name} - Pessoas Vermelhas`;
      case STATUS.PEOPLE_DYNAMO:
        return `${cityData.name} - Pessoas com perfil Dínamo`;
      case STATUS.PEOPLE_VOLUNTEER:
        return `${cityData.name} - Pessoas com perfil Voluntário`;
      case STATUS.AMENDMENTS:
        return `${cityData.name} - Emendas`;
      case STATUS.ELECTED:
        return `${cityData.name} - Eleitos`;
      case STATUS.PRE_CANDIDATES:
        return `${cityData.name} - Pré-candidatos`;
      case STATUS.CANDIDATES:
        return `${cityData.name} - Candidatos`;
      default:
        return `${cityData.name}`;
    }
  }, [status, cityData.name]);

  const backToInitialPage = useCallback(() => setStatus('initial'), []);

  const graphicLink = useMemo(
    () =>
      `https://graph-state-2020-a000000000000000000000000000000000000-16042020.netlify.app/graficounico/${cityData.name}.png`,
    [cityData.name]
  );

  const content = useMemo(() => {
    if (cityData.id === -1) return <></>;

    switch (status) {
      case 'initial':
        return (
          <InitialPage
            {...{ cityData: cityData.data, graphicLink, setStatus }}
          />
        );

      case 'graphic':
        return (
          <Image
            src={graphicLink}
            aspectRatio={4 / 3}
            style={{ padding: 0 }}
            imageStyle={{ position: 'unset' }}
          />
        );

      default:
        return <DataTable {...{ city: cityData.id, status }} />;
    }
  }, [cityData, status, graphicLink]);

  return (
    <DialogCityProvider title={title}>
      <Dialog
        {...{ show, title }}
        onClose={closeDialog}
        backAction={status !== 'initial' ? backToInitialPage : undefined}
        style={status !== 'initial' ? { width: '75vw' } : {}}
      >
        {content}
      </Dialog>
    </DialogCityProvider>
  );
}

DialogCity.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogCity;
