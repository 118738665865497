import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import DialogCityContext from '../../../../assets/context';

function ExportToExcel({ dtRef, filename }) {
  const { title } = useContext(DialogCityContext);
  const exportExcel = () => {
    // Obtém os dados diretamente da tabela, considerando que dtRef.current possui os dados filtrados
    const table = dtRef.current;
    const htmlTable = dtRef.current.container;

    if (!table) {
      console.error('Tabela não encontrada!');
      return;
    }

    const tableData = table.props.value;

    // Função para validar URLs
    const isValidURL = string => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };

    // Clona a tabela HTML
    const cloneTable = htmlTable.cloneNode(true);

    // Remove a segunda linha (linha de filtro)
    const filterRow = cloneTable.querySelector('thead tr:nth-child(2)'); //seleciona a segunda linha
    if (filterRow) {
      filterRow.remove(); // Remove a linha do filtro
    }

    // Transforma os dados em um formato que o XLSX pode entender - w faz referência à worksheet primeira, antes da conversão
    const worksheet = XLSX.utils.table_to_sheet(cloneTable, {});

    // Adiciona a opção de filtro no cabeçalho de cada coluna
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

    // Encontra a coluna "Detalhes"
    let detalhesColumnIndex = null;
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (worksheet[cellAddress]?.v === 'Detalhes') {
        detalhesColumnIndex = C;
        break;
      }
    }

    // Se a coluna "Detalhes" for encontrada
    if (detalhesColumnIndex !== null) {
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({
          r: R,
          c: detalhesColumnIndex,
        });
        const cellValue = worksheet[cellAddress]?.v;

        // Verifica se o valor é um URL válido
        if (cellValue && isValidURL(cellValue)) {
          worksheet[cellAddress].l = { Target: cellValue }; // Adiciona hyperlink
          worksheet[cellAddress].s = {
            font: { color: { rgb: '0000FF' }, underline: true }, // Formata o hyperlink
          };
        }
      }
    }

    // Função para definir o estilo da borda
    const borderStyle = {
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
    };

    // Adiciona cor de fundo ao cabeçalho
    const headerRow = 0; // A primeira linha (índice 0) é o cabeçalho
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: C });
      if (!worksheet[cellAddress]) continue;

      worksheet[cellAddress].s = {
        fill: {
          fgColor: { rgb: 'D3D3D3' }, // Cinza claro
        },
        font: {
          bold: true,
        },
        ...borderStyle, // Adiciona borda
      };
    }

    // Aplica a cor correspondente ao valor na coluna "Cor"
    let corColumnIndex = null;

    // Identifica o índice da coluna "Cor"
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: C });
      if (worksheet[cellAddress]?.v === 'Cor') {
        corColumnIndex = C;
        break;
      }
    }

    // Se a coluna "Cor" for encontrada, aplica a cor correspondente ao conteúdo
    if (corColumnIndex !== null) {
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: corColumnIndex });
        const cellValue = worksheet[cellAddress]?.v;
        if (cellValue) {
          let fillColor = null;
          let fontColor = '000000';

          switch (cellValue.toLowerCase()) {
            case 'amarelo':
              fillColor = 'FFFF00';
              break;
            case 'azul':
              fillColor = '0000FF';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            case 'cinza':
              fillColor = '808080';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            case 'laranja':
              fillColor = 'FFA500';
              break;
            case 'preto':
              fillColor = '000000';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            case 'roxo':
              fillColor = '800080';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            case 'verde':
              fillColor = '008000';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            case 'vermelho':
              fillColor = 'FF0000';
              fontColor = 'FFFFFF'; // Define a cor da fonte como branca
              break;
            default:
              fillColor = null;
          }

          if (fillColor) {
            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: fillColor },
              },
              font: {
                color: { rgb: fontColor }, // Aplica a cor da fonte
              },
              ...borderStyle, // Adiciona borda
            };
          }
        }
      }
    }

    // Adiciona bordas a todas as células da planilha
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellAddress]) continue;

        worksheet[cellAddress].s = {
          ...worksheet[cellAddress].s,
          ...borderStyle, // Adiciona borda
        };
      }
    }

    // Função para calcular a largura ideal da coluna com base no conteúdo
    function autoFitColumns(worksheet) {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      const colWidths = new Array(range.e.c - range.s.c + 1).fill(10); // Largura mínima inicial

      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { r: R, c: C };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cell = worksheet[cellRef];

          if (cell && cell.v) {
            const cellValue = cell.v.toString();
            const cellWidth = cellValue.length + 2; // Ajusta o tamanho com base no comprimento do texto

            if (colWidths[C] < cellWidth) {
              colWidths[C] = cellWidth;
            }
          }
        }
      }
      worksheet['!cols'] = colWidths.map(w => ({ wch: w }));
    }
    // Aplica o ajuste automático de largura das colunas
    autoFitColumns(worksheet);

    /*ToDo: Verificar se é possível congelar a linha superior do cabeçalho da planilha
    // Congela a linha superior (primeira linha)
    worksheet['!freeze'] = {
      xSplit: 0, // Nenhuma coluna é congelada
      ySplit: 1, // Congela a primeira linha
      topLeftCell: 'A2', // Define a célula que estará no canto superior esquerdo após o congelamento
      activePane: 'bottomLeft', // Define o painel ativo
      state: 'frozen', // Define o estado como "frozen" para congelar a linha
    };*/

    // Cria o workbook e adiciona a planilha
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabela');

    // Converte o workbook para um buffer de array e cria um Blob
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Salva o arquivo
    saveAs(data, filename || title);
  };

  // cria o botão de exportar e o formata
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px',
      }}
    >
      <Button
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </div>
  );
}

export default ExportToExcel;
