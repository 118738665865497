import React, { useCallback, useMemo, useState } from 'react';

import { chaveExisteEm } from '../../utils';

import AbasContext from './assets/AbasContext';

import { ItemAba, AbasWrapper, ConteudoAba } from './styles';

function a11yProps(value) {
  return {
    id: `aba-${value}`,
    'aria-controls': `aba-${value}`,
  };
}

export function Aba(props) {
  const { componente, renderizarEm, ...resto } = props;
  return <ItemAba {...resto} disableRipple />;
}

export function Abas(props) {
  const {
    children,
    contexto,
    renderizarAbasEm = <ConteudoAba />,
    ...resto
  } = props;

  const [aba, setAba] = useState(() => {
    const abas = React.Children.toArray(children);
    const primeiroAtivo = abas.find(item => !item.props.disabled);
    return primeiroAtivo.props.value || abas.indexOf(primeiroAtivo);
  });

  const abaAtual = useMemo(() => resto.value || aba, [resto.value, aba]);

  const alterarAba = useCallback(aba => setAba(aba), []);

  const abas = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        const { componente, renderizarEm, value } = child.props;
        const ConteudoAba = renderizarAbasEm || renderizarEm;
        return React.cloneElement(
          ConteudoAba,
          {
            ...ConteudoAba.props,
            className: `conteudo-aba ${ConteudoAba.props.className || ''}`,
            id: `conteudo-aba-${value || index}`,
            role: 'tabpanel',
            hidden: (value || index) !== abaAtual,
            'aria-labelledby': `aba-${value || index}`,
          },
          componente
        );
      }),
    [children, abaAtual, renderizarAbasEm]
  );

  return (
    <AbasContext.Provider value={{ ...contexto, abaAtual }}>
      <AbasWrapper
        {...resto}
        value={abaAtual}
        onChange={(e, aba) => {
          if (chaveExisteEm(resto, 'onChange')) resto.onChange(e, aba);
          // quando componente controlado ignorar estado interno
          if (!chaveExisteEm(resto, 'value')) alterarAba(aba);
        }}
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            ...child.props,
            ...a11yProps(child.props.value || index),
            value: child.props.value || index,
          })
        )}
      </AbasWrapper>
      {abas}
    </AbasContext.Provider>
  );
}
