import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../styles.css';

import { Botao } from '../../../../../../../../components';

import { analytics } from '../../../../../../../../';

import { PEOPLE_COLS } from '../../../../assets/constants';

import ExportToExcel from '../ExportToExcel';

import {
  rowCellBodyTemplate,
  rowCellColorBodyTemplate,
  multiselectItemTemplate,
  multiselectColorItemTemplate,
} from '../assets/templates';

function PessoasTable(props) {
  const { rows } = props;

  const [corOptions, setCorOptions] = useState([]);

  const [selectedCor, setSelectedCor] = useState(null);

  const dt = useRef(null);

  // useEffect impede conflito de opções do multiselect na mudança de abas/anos
  // useEffect(() => {
  //   if (currentTab) {
  //     setSelectedCor(null);
  //     dt.current.reset();
  //   }
  // }, [currentTab]);

  /**
   * Este useEffect pega as informações que estao contidas na tabela
   * para criar arrays de opçoes para os filtros de multiselect
   * existentes na tabela escolhido em InitialPage
   * Atualmente, apenas coluna Cor nas tabelas de pessoa.
   */
  useEffect(() => {
    if (rows.length > 0) {
      const array = rows.map(item => item.Cor.id_cor || '');
      const values = new Set(array);
      const colors = Array.from(values).map(
        id =>
          rows
            .map(item => item.Cor)
            .find(({ id_cor: idcor }) => idcor === id) || { nome: 'Sem Cor' }
      );
      setCorOptions(colors);
    }
  }, [rows]);

  const onMultiSelectChange = (e, column) => {
    dt.current.filter(e.value, column, 'in');
    if (column === PEOPLE_COLS.color) {
      setSelectedCor(e.value);
    }
  };

  const getFilterType = useCallback(key => {
    if (
      [
        PEOPLE_COLS.name,
        PEOPLE_COLS.nickname,
        PEOPLE_COLS.EFL,
        PEOPLE_COLS.SVN,
      ].includes(key)
    ) {
      return 'Text';
    }

    if (key === PEOPLE_COLS.color) {
      return 'MultiSelect';
    }

    return '';
  }, []);

  const getKeyVariables = useCallback(
    key => {
      if (key === PEOPLE_COLS.color) {
        return [selectedCor, corOptions];
      }

      return [];
    },
    [corOptions, selectedCor]
  );

  const getFilterElement = useCallback(
    (key, type) => {
      const [value, options] = getKeyVariables(key);
      if (type === 'MultiSelect' && options.length > 1) {
        return key !== PEOPLE_COLS.color ? (
          <MultiSelect
            className="p-column-filter"
            value={value}
            options={options}
            onChange={e => onMultiSelectChange(e, key)}
            itemTemplate={multiselectItemTemplate}
            placeholder="Todos"
          />
        ) : (
          <MultiSelect
            className="p-column-filter"
            value={value}
            options={options}
            optionLabel={option => option.cor_nome}
            onChange={e => onMultiSelectChange(e, key)}
            itemTemplate={multiselectColorItemTemplate}
            placeholder="Todas"
          />
        );
      }
      return <></>;
    },
    [getKeyVariables]
  );

  const columns = useMemo(() => {
    const [first] = rows;
    if (!first) {
      return [<Column />];
    }
    return Object.keys(first).map(key => {
      const filterType = getFilterType(key);
      const filterElement = getFilterElement(key, filterType);
      return filterType !== 'Text' && filterType !== '' ? (
        <Column
          field={key}
          header={key}
          body={rowData =>
            key !== PEOPLE_COLS.color
              ? rowCellBodyTemplate(key, rowData)
              : rowCellColorBodyTemplate(rowData)
          }
          filter
          filterElement={filterElement}
          sortable
          sortField={rowData =>
            key === PEOPLE_COLS.color && rowData.Cor.cor_nome
              ? rowData.Cor.cor_nome
              : undefined
          }
        />
      ) : (
        <Column
          field={key}
          header={key}
          body={rowData =>
            key !== PEOPLE_COLS.details ? (
              rowCellBodyTemplate(key, rowData)
            ) : (
              <Botao
                sx={{
                  '&:before': {
                    content: '"Ver Cadastro"',
                  },
                }}
                style={{
                  backgroundColor: '#3498DB',
                  color: '#fff',
                }}
                onClick={() => {
                  window.open(rowData.Detalhes, '_blank');
                  analytics.logEvent('detalhes-pessoa-abrir-app-voluntário');
                }}
              >
                <span style={{ display: 'none' }}>{rowData.Detalhes}</span>
              </Botao>
            )
          }
          filter={key !== 'Detalhes'}
          filterPlaceholder={
            key === 'EFL' || key === 'SVN' ? 'Mínimo' : 'Filtro por Nome'
          }
          filterMatchMode={key === 'EFL' || key === 'SVN' ? 'gte' : 'contains'}
          filterMaxLength={key === 'EFL' || key === 'SVN' ? 10 : 30}
          sortable={key !== 'Detalhes'}
        />
      );
    });
  }, [getFilterElement, getFilterType, rows]);

  return (
    <div className="datatable-div">
      <div className="card">
        <ExportToExcel dtRef={dt} />
        <DataTable
          ref={dt}
          value={rows}
          rows={rows.length}
          className="p-datatable-component"
          scrollable
          removableSort
          emptyMessage="Não há itens a serem listados."
        >
          {columns}
        </DataTable>
      </div>
    </div>
  );
}

export default PessoasTable;
