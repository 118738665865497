import React from 'react';

/**
 * Templates usados para construir as celulas de qualquer tabela
 */

export const rowCellBodyTemplate = (column, rowData) => {
  const text = rowData[column] ? rowData[column].toLocaleString('pt-BR') : null;
  const reducedText = text ? `${text.substr(0, 80)}...` : null;
  return (
    <>
      {/* <span className="p-column-title">{column}</span> */}
      {text && (
        <span title={text.length > 80 && text}>
          {text.length > 80 ? reducedText : text}
        </span>
      )}
    </>
  );
};

// especifico para colunas de cor que retornam objetos como dado
export const rowCellColorBodyTemplate = rowData => (
  <>
    {/* <span className="p-column-title">Cor</span> */}
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {((rowData.Cor.nome && rowData.Cor.nome !== 'Sem Cor') ||
        (rowData.Cor.cor_nome && rowData.Cor.cor_nome !== 'Sem Cor')) && (
        <div
          style={{
            backgroundColor: rowData.Cor.hexa || rowData.Cor.cor_hexa,
            width: 20,
            height: 20,
            borderRadius: 4,
          }}
        />
      )}
      <span style={{ paddingLeft: 10 }}>
        {rowData.Cor.nome || rowData.Cor.cor_nome}
      </span>
    </div>
  </>
);

/**
 * Para uma determinada coluna, faz a exibição de um fundo colorido com texto em contraste
 * O item da coluna deve ser um objeto com obrigatoriamente três campos do tipo String:
 *  - `text`: texto que será exibido na célula
 *  - `textColor`: cor do texto
 *  - `bgColor`: cor do fundo da célula
 * @param {Object} rowData objeto com os dados de cada coluna da linha atual
 * @param {String} column string com a key da coluna desejada
 */
export const rowCellColorTextBgTemplate = (rowData, column) => (
  <div
    style={{
      backgroundColor: rowData[column].bgColor,
      borderRadius: 4,
      textAlign: 'center',
      padding: '6px 10px',
    }}
  >
    <span style={{ color: rowData[column].textColor }}>
      {rowData[column].text}
    </span>
  </div>
);

// --------------------------------------

/**
 * Templates usados para criar os itens de opções de filtro das tabelas
 */

export const multiselectItemTemplate = option => (
  <div className="p-multiselect-option">
    <span className="image-text">{option}</span>
  </div>
);

// especifico para cores que retornam objeto como dado
export const multiselectColorItemTemplate = option => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
    className="p-multiselect-option"
  >
    {((option.nome && option.nome !== 'Sem Cor') ||
      (option.cor_nome && option.cor_nome !== 'Sem Cor')) && (
      <div
        style={{
          backgroundColor: option.hexa || option.cor_hexa,
          width: 15,
          height: 15,
          borderRadius: 4,
        }}
      />
    )}
    <span style={{ paddingLeft: 10 }} className="image-text">
      {option.nome || option.cor_nome}
    </span>
  </div>
);
